.mode {
    height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 1rem;
    background-color: #DAD1DA;
}

.mode-win {
    background-color: #58A0A0;
    color: #FAF1FA;
}

.mode-gameover {
    background-color: #C8A3A0;
}

.info {
    width: 6rem;
}

.mode button {
    width: 4.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border: 1px solid black;
    background-color: #FAF1FA;
    cursor: pointer;
}

.mode button:hover {
    opacity: 0.5;
}

button:disabled {
    opacity: 1;
    cursor: default;
}

.action button:nth-of-type(1) {
    border-right: none;
}

.action button:nth-of-type(2) {
    border-left: none;
}